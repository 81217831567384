.App-body {

    min-height: 100vh;
    color: white;
    /* background-color: rgb(209, 209, 209); */
    background-color: black;
}

.About a, .About a:visited {
  color: lightgreen
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.App-body-content {
    /* padding: 10px; */
}

.App-link {
    color: #61dafb;
}


.flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.loading{
    text-align: center;
    margin-top: 10px;
    color: black;
    font-weight: bold;
    background-color: lightgrey;
}

/* ======  NAV  ====== */

nav {
    padding: 0px 5%;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(170, 170, 170);
    color: white;
    width: 100%;
    background-color: #11273d !important;

    justify-content: space-between !important;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
/*    position: fixed;*/
    top: 0;
    z-index: 1;
}




nav h1 {
    display: block;
    text-align: center;
    color: white;
    font-size: 40px;
    padding: 1rem 0;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
} 

nav div{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

nav div div{
    display: flex;
}

nav h2 {
    font-size: 30px;
}

.nav-item:visited {
    display: block;
    border: 3px solid #0C7B93;
    background-color: #0C7B93 !important;
    text-align: center;
}

.nav-item:hover {
    /* border: 3px solid #11273d; */
    color: white;
    text-align: center;
    background-color: #27496D !important;
}

.nav-item {
    display: block;
    padding: 10px 30px;
    font-size: 1.2em;
    color: white;
    margin: 0 10px;
/*    border-radius: 30px;*/

     background-color: #0C7B93 !important; 
}

.nav-item.selected {
    background-color: #0C7B93;
    outline: 1px solid white;
/*    border-radius: 30px;*/
}




/* ======  LIST VIEW MANAGER  ====== */


.ListViewManager .date-range-select {
    background-color: #11273d !important;
    color: white;
    text-align: center;
}

.ListViewManager h2{
    color: black;
    text-align: center;
/*    padding-top: 100px;*/
}

.ListViewManager .date-range-select:hover {
    background-color: #27496D !important;
    color: white;
}

.ListViewManager .date-range-select-static {
    background-color: #0C7B93 !important;
    text-align: center;
}

/* .img-background {
    background-image: url("vendor/hatching_adjusted.png");
    border-radius: 20px;
} */


.background {
    background-color: #282c34;
}

.VenuesList {
    /*background-color: DarkSlateBlue;*/
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-around;
}
.VenuesListContainer{
/*    margin-top: 100px;*/
    padding: 5%;
    padding-top: 0;
}

.eventDates {
    display: flex;
    justify-content: center;
}

.venue-container{
    width: 30%;
    margin-bottom: 1rem;
    outline: 2px solid rgb(170, 170, 170);
    background-color: #ebebeb; 
    /* width: 30%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
}
 
.venue-container:hover {
    box-shadow: 10px 5px 5px grey;
}

.venue-name {
    min-width: 200px;
    /* float: left; */
    font-weight: bold;
    font-size: 1.1em;
    width: 100%;
    background-color: #27496D;

    text-align: center;
    padding: 4px;
    padding-top: 8px;

    display: flex;
    justify-content: space-between;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.venue-name .location-icon{
    float: unset; 
}


.description-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.venue-description {
    /* display: inline-block; */
    margin-top: 10px;
    /* font-style: italic; */
    /*min-height: 150px;*/
    color: #27496D;
    font-size: 1.2em;
    font-family: Impact;
    padding: 10px;
}

.venue-link {
    display: block;
    text-align: center;
    padding: 10px 30px;
    /* margin-right: 10px; */
    background-color: #0C7B93 !important;
    color: white;
    outline: 1px solid rgb(170, 170, 170);
    width: 40%;
    border-radius: 30px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.venue-link:hover {
    background-color: #27496D !important;
    color: white;
}

.venue-view-events {
    margin-right: 10px;
    padding: 0 10px;
    float: right;
    /*border: 1px solid black;*/
    display: inline-block;
    background-color: #0C7B93 !important;
    color: white;
    outline: 1px solid rgb(170, 170, 170);
}

.venue-view-events:hover {
    background-color: #27496D !important;
    color: white;
}



.venue-region-title {
    /* background-color: #27496D !important; */
    display: block;
    font-size: 1.5em;
    padding: 10px;
    padding-top: 5%;
    text-align: center;

    margin-bottom: 70px;
    font-size: 2em;
    text-align: center;
    color: black;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid black;
    text-align: center;
}

.venue-region-title-text{
    font-size: 40px;
    background-color: black;
/*    outline: 1px solid white;*/
    color: white;
    padding: 0 10px;
}


.Day-group {
    /*margin-right: 20px;*/
    /*outline: 1px solid black;*/
    padding: 2%;
}

.daygroup-title {
    margin-bottom: 50px;
    font-size: 2em;
    text-align: center;
    color: black;

    width: 100%;
    height: 30px;
    border-bottom: 1px solid black;
    text-align: center
}

.daygroup-title-text {
    font-size: 40px;
    background-color: black;
    color: white;
    padding: 0 10px;
}

.masonry-with-columns {
    columns: 6 250px;
    column-gap: 1rem;
}

.masonry-with-columns .masonry-item {
    width: 150px;
    background: #EC985A;
    color: white;
    margin: 0 1rem 1rem 0;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-family: system-ui;
    font-weight: 900;
    font-size: 2rem;
}

.Event-box {
    display: flex;
    max-width: 250px;
    flex-direction: column;
    /* outline: 2px solid rgb(170, 170, 170); */
    border-radius: 10px;
    position: relative;
    text-align: center;
    color: white;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.img-container {
/*    width: 100%;*/
    /* background-color: #27496D ; */
}

.Event-img {
    display: block;
    /* width: auto; */
/*    width: 100%;*/
/*    width: 50%;*/
/*    max-width: 200px;*/
/*    max-height: 2pp;*/
/*     height: 250px; */
    /* height: 50%; */
    border-radius: 20px;
}

.event-description {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    /* max-height: 100%; */
    border-radius: 20px;
    background-color: rgba(54, 54, 54, 0.848);
    cursor: pointer;
}

.event-link {
    color: black !important;
    display: inline-block;
/*    width: 30%;*/
/*    display: block;*/
/*    margin: 0 auto;*/
    max-width: 250px !important;
}

.event-venue {
    display: inline-block;
    /* background-color: #27496D; */
    color: white;
    width: 100%;
    text-align: center;
/*    font-size: xx-large;*/
    padding: 4px;
}

.event-title {
    display: inline-block;
    font-weight: bold;
    font-size: 1.0em;
    padding: 5px;
}

.pseudo-link {
/*    color: green;*/
}

.box.selected {
    background-color: lightblue;
}

a .box {
    transition: background-color 0.25s ease-in-out;
}

a .box:hover {
    background-color: lightblue;
}

.white {
    color: white !important;
}

.search {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.search input[type='text'] {
    height: 30px;
    font-size: 1.1em;
}

/* EVENT LIST ITEM -- TEXT ONLY */
.textViewEntry {
    outline: 1px solid black;
    /* background-color: #ebebeb;   */
    border-radius: 10px;
    overflow: hidden;
    /* width: 30%; */
    margin: 0 auto;
    padding-left: 3px;
    padding-bottom: 10px;
    margin-bottom: 0.3rem;
    /* outline: 1px solid white; */
    border-bottom: 1px solid white;
}

.region-title {
    color: red;
    font-size: 2em;
    margin-left: 10px;
}

.textViewEntry:hover {
    outline: 1px solid white;
    box-shadow: 10px 5px 5px grey;
}

.textViewEntry .textViewVenue {
    font-weight: bold;
    font-size: 1.2em;
    /* color: black; */
    color: white;
    /* background-color: #27496D; */
    text-align: center;
    padding: 4px;
    padding-top: 8px;
}

.textViewEntry .textViewTitle {
    padding: 10px;
    /* display: block; */
}

.textViewEntry .textViewLink, .textViewEntry .textViewLink:visited {
    /* color: #27496D; */
    color: lightblue;
    font-size: 1.2em;
    width: 100%;
    display: block;
    /* font-family: Impact; */
    letter-spacing: 1px;
}



.location-icon {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 5px solid white;
    border-radius: 100%;
    float: left;
    margin-left: 10px;
    margin-right: -10px;
    margin-top: 4px;
}

.location-icon::after {
    position: absolute;
    top: 100%;
    left: 50%;
    margin: 1px 0 0 -6px;
    display: block;
    content: '';
    border: 6px solid transparent;
    border-top: 10px solid white;
    border-bottom: none;
}


.About {
    padding: 30px;
/*    padding-top: 120px;*/
}
/*@media only screen and (min-width: 1370) {
}*/


@media only screen and (max-width: 1370px){
    .venue-container{
        width: 45%;
    }
    nav{
        flex-direction: column !important;
        padding-bottom: 10px;
    }
/*    .About {
        padding: 0 30px;
        padding-top: 180px;
    }*/

}
@media only screen and (max-width: 860px){

/*    .ListViewManager, .VenuesListContainer {
        padding-top: 50px;
    }*/

    nav div {
        flex-direction: column !important;
    }
/*    nav div div {
        text-align: center;
        flex-direction: row !important;
    }*/
    nav div div {
        flex-direction: column !important;
    }

    .nav-item {
        margin: 5px;
    }

    .venue-container{
        width: 100%;
    }

    .venue-region-title{
        height: 70px;
    }

/*    .About {
        padding: 0 30px;
        padding-top: 380px;
    }*/
 
}
@media only screen and (max-width: 560px){
    .eventDates{
        flex-direction: column !important;
        grid-gap: 10px;
    }

    .masonry-with-columns {
        columns: 1 100%;
    }

    .Event-box {
        width: 60vw;
        margin-left: 15vw;
    }


 
}
@media only screen and (max-width: 510px){
 
/*    .ListViewManager, .VenuesListContainer {
        padding-top: 200px;
    }*/
    .venue-region-title{
        height: 55px;
    }
 
    nav div div {
        
        flex-direction: column !important;
    }
/*    .About {
        padding: 0 30px;
        padding-top: 380px;
    }*/
 
}
